@import '@/core/tamagoshi/scss';

.block {
  background-color: $n0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: $spacingLg;
  align-items: center;
  padding: $spacingMd;
}
